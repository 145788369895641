exports.components = {
  "component---src-pages-404-index-ts": () => import("./../../../src/pages/404/index.ts" /* webpackChunkName: "component---src-pages-404-index-ts" */),
  "component---src-pages-404-view-tsx": () => import("./../../../src/pages/404/view.tsx" /* webpackChunkName: "component---src-pages-404-view-tsx" */),
  "component---src-pages-aviso-privacidade-index-ts": () => import("./../../../src/pages/aviso-privacidade/index.ts" /* webpackChunkName: "component---src-pages-aviso-privacidade-index-ts" */),
  "component---src-pages-aviso-privacidade-view-tsx": () => import("./../../../src/pages/aviso-privacidade/view.tsx" /* webpackChunkName: "component---src-pages-aviso-privacidade-view-tsx" */),
  "component---src-pages-cookies-index-ts": () => import("./../../../src/pages/cookies/index.ts" /* webpackChunkName: "component---src-pages-cookies-index-ts" */),
  "component---src-pages-cookies-view-tsx": () => import("./../../../src/pages/cookies/view.tsx" /* webpackChunkName: "component---src-pages-cookies-view-tsx" */),
  "component---src-pages-imprint-index-ts": () => import("./../../../src/pages/imprint/index.ts" /* webpackChunkName: "component---src-pages-imprint-index-ts" */),
  "component---src-pages-imprint-view-tsx": () => import("./../../../src/pages/imprint/view.tsx" /* webpackChunkName: "component---src-pages-imprint-view-tsx" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-portal-privacidade-index-ts": () => import("./../../../src/pages/portal-privacidade/index.ts" /* webpackChunkName: "component---src-pages-portal-privacidade-index-ts" */),
  "component---src-pages-portal-privacidade-view-tsx": () => import("./../../../src/pages/portal-privacidade/view.tsx" /* webpackChunkName: "component---src-pages-portal-privacidade-view-tsx" */),
  "component---src-pages-privacidade-index-ts": () => import("./../../../src/pages/privacidade/index.ts" /* webpackChunkName: "component---src-pages-privacidade-index-ts" */),
  "component---src-pages-privacidade-view-tsx": () => import("./../../../src/pages/privacidade/view.tsx" /* webpackChunkName: "component---src-pages-privacidade-view-tsx" */),
  "component---src-pages-privacy-index-ts": () => import("./../../../src/pages/privacy/index.ts" /* webpackChunkName: "component---src-pages-privacy-index-ts" */),
  "component---src-pages-privacy-notice-index-ts": () => import("./../../../src/pages/privacy-notice/index.ts" /* webpackChunkName: "component---src-pages-privacy-notice-index-ts" */),
  "component---src-pages-privacy-notice-view-tsx": () => import("./../../../src/pages/privacy-notice/view.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-view-tsx" */),
  "component---src-pages-privacy-request-index-ts": () => import("./../../../src/pages/privacy-request/index.ts" /* webpackChunkName: "component---src-pages-privacy-request-index-ts" */),
  "component---src-pages-privacy-request-view-tsx": () => import("./../../../src/pages/privacy-request/view.tsx" /* webpackChunkName: "component---src-pages-privacy-request-view-tsx" */),
  "component---src-pages-privacy-view-tsx": () => import("./../../../src/pages/privacy/view.tsx" /* webpackChunkName: "component---src-pages-privacy-view-tsx" */),
  "component---src-pages-termos-index-ts": () => import("./../../../src/pages/termos/index.ts" /* webpackChunkName: "component---src-pages-termos-index-ts" */),
  "component---src-pages-termos-view-tsx": () => import("./../../../src/pages/termos/view.tsx" /* webpackChunkName: "component---src-pages-termos-view-tsx" */),
  "component---src-pages-terms-index-ts": () => import("./../../../src/pages/terms/index.ts" /* webpackChunkName: "component---src-pages-terms-index-ts" */),
  "component---src-pages-terms-view-tsx": () => import("./../../../src/pages/terms/view.tsx" /* webpackChunkName: "component---src-pages-terms-view-tsx" */),
  "component---src-pages-view-tsx": () => import("./../../../src/pages/view.tsx" /* webpackChunkName: "component---src-pages-view-tsx" */)
}

